$(document).on('init', function(event) {
    var target = $(event.target);
    target.find('input[placeholder], textarea[placeholder]').placeholder();
    /*target.find('.phonemask')
        .focus(function() {
            var el = $(this);
            if(!el.val()) el.val('+7 (').trigger('change');
        })
        .blur(function() {
            var el = $(this);
            if(el.val()=='+7 (') el.val('').trigger('change');
        })
        .mask('+7 (000) 000-00-00');*/
    target.find('input.iselect').iselect();
    target.find('[title]').qtip({
        position: {
            target: 'mouse'
        }
    });
});

$(function() {
    $(document).trigger('init');

    $('.site_index_banner a').click(function(event) {
        if($(this).find('.cke_editable').length) {
            event.preventDefault();
        }
    });

    $.ajaxSetup({cache:false});
    setInterval(function() {$.ajax('/site/ping', {cache:false})}, 10000);
    $('img.hover').imghover();
    $('.tabs1').tabs('.tabs li', '.tab');
    $('.tabs2').tabs('.tabs li', '.tab');
    $('#bottommenu li.l1').rowheight();
    $('#mainmenu .cnt1').mymenu();

    $('#header .account-cnt .bask').click(function() {
        location.href = $(this).find('a:first').attr('href');
    });

    $('#leftmenu .a1').click(function(event) {
        if(event.target==this) {
            var li = $(this).closest('.l1');
            if(li.hasClass('have-sub')) {
                li.toggleClass('active');
                event.preventDefault();
            }
        }
    });

    $('.btn-bookmarks').click(function(event) {
        event.preventDefault();

        var link = $(this);

        $.ajax(link.attr('href'), {
            cache:false,
            dataType:'html',
            success:function() {
                var top = $(document.body).scrollTop() + Math.round($(window).height()/2) - 100;
                var mess = $('<div class="buymess" />')
                    .css({position:'absolute', left:'50%', top:top+'px', opacity:0});
                var cnt = $('<div class="cnt" />').appendTo(mess);
                cnt.html(LANG['favoritemess']);
                $(document.body).append(mess);

                mess.animate({opacity:100}, 500, function() {
                    setTimeout(function() {
                        $.ajax('/widget/?widget=WFavorite&method=j', {
                            cache:false,
                            dataType:'html',
                            success:function(html) {
                                $('#WFavorite').replaceWith(html);

                                var favorite = $('#WFavorite');
                                cnt.animate({width:favorite.width(), height:favorite.height(), left:0}, 300);
                                mess.animate({top:favorite.offset().top, left:favorite.offset().left}, 500,
                                    function() {
                                        setTimeout(function() {
                                            mess.remove();
                                        }, 1)
                                    });
                            }
                        });
                    }, 500);
                });
            }
        });
    });

    $('form.buyform').submit(function(event) {
        event.preventDefault();

        var form = $(this);
        var post = form.serializeArray();

        $.ajax(form.attr('action'), {
            type:'post',
            data:post,
            cache:false,
            dataType:'html',
            success:function() {
                $.ajax('/widget/?widget=WBask&method=j', {
                    cache:false,
                    dataType:'html',
                    success:function(html) {
                        $('#WBask').replaceWith(html);
                    }
                });

                var dialog = $.myDialog();
                dialog.width(490);
                dialog.$.closest('.myDialog-window').addClass('myDialog-window-bask');

                $.ajax('/order/bask', {
                    cache:false,
                    dataType:'html',
                    success:function(html) {
                        dialog.html(html);
                    }
                });
            }
        });
    });

    $('#productlist .product-list .item .c2 .footer a').click(function(event) {
        event.preventDefault();
        var link = $(this);
        if(!link.hasClass('open')) {
            link.closest('td').find('.field.h').show(500);
            link.addClass('open');
        } else {
            link.closest('td').find('.field.h').hide(500);
            link.removeClass('open');
        }
    });

    $('.btn-onebuy').click(function(event) {
        event.preventDefault();

        var link = $(this);

        var dialog = $.myDialog();
        dialog.width(820);
        dialog.$.closest('.myDialog-window').addClass('myDialog-window-easybuy');

        $.ajax(link.attr('href'), {
            cache:false,
            dataType:'html',
            success:function(html) {
                CMS.registerLoadedScripts();
                dialog.html(html);
                CMS.runScripts(html);
            }
        });
    });

    $(window).scroll(function() {
        if($(window).scrollTop()>500) {
            $('.m-site-up').stop(true).fadeIn();
        } else {
            $('.m-site-up').stop(true).fadeOut();
        }
    });
    if($(window).scrollTop()>500) {
        $('.m-site-up').stop(true).fadeIn();
    } else {
        $('.m-site-up').hide();
    }
    $('.m-site-up').click(function() {
        $('html, body').animate({scrollTop:0}, '1000');
    });
    
    $('.menu-horizontal li.l1').mymenu();
});

(function($) {
    var tabs_counter = 0;
    $.fn.tabs = function(tabsSelector, tabsContentSelector, activeTabClass, activeTabContentClass) {
        this.each(function() {
            var element = $(this);

            if(element.data('tabs')) return;
            element.data('tabs', true);

            if(!tabsSelector) tabsSelector = '.tab';
            if(!tabsContentSelector) tabsContentSelector = '.tab-content';
            if(!activeTabClass) activeTabClass = 'active';
            if(!activeTabContentClass) activeTabContentClass = 'active';

            var tabs = element.find(tabsSelector).disableSelection();
            var active = tabs.filter('.'+activeTabClass).prevAll().length + 1;
            tabs.removeClass(activeTabClass);

            element.find(tabsContentSelector+'[data-ajax]').on('show', function() {
                var el = $(this);
                if(!el.html()) {
                    //el.html('<img src="/img/preloader2.gif">');
                    $.ajax(el.data('ajax'), {
                        headers: {tabs:true},
                        success:function(html) {
                            el.html(html);
                            /*CMS.registerLoadedScripts();
                             CMS.runScripts(html);*/
                        }
                    });
                }
            });

            var i = 0;
            tabs.each(function() {
                i++;
                tabs_counter++;
                var tab = $(this);
                tab.data('num', i).data('anchor', tabs_counter);
                if(location.hash=='#tabs-'+tabs_counter) {
                    active = i;
                    setTimeout(function() {
                        $(document).scrollTop(tab.offset().top);
                    }, 300);
                }
            }).eq(active-1).addClass(activeTabClass);

            var i = 0;
            var tabsCnt = element.find(tabsContentSelector);
            tabsCnt.each(function() {
                i++;
                $(this).addClass('tab-'+i);
            }).removeClass(activeTabContentClass).eq(active-1).addClass(activeTabContentClass).trigger('show');

            tabs.off().click(function(event) {
                event.preventDefault();
                event.stopImmediatePropagation();

                tabs.removeClass(activeTabClass);
                $(this).addClass(activeTabClass);

                location.hash = '#tabs-'+$(this).data('anchor');

                tabsCnt.removeClass(activeTabContentClass);
                tabsCnt.filter('.tab-'+$(this).data('num')).addClass(activeTabContentClass).trigger('show');
            });
        });
    }

    $.fn.mymenu = function() {
        var self = this;
        self.hover(
            function() {
                var el = $(this);
                self.removeClass('hover');
                var timer = el.data('timer');
                if(timer) {
                    clearTimeout(timer);
                }
                el.addClass('hover');
            },
            function() {
                var el = $(this);
                var timer = setTimeout(function() {
                    el.data('timer', null);
                    el.removeClass('hover');
                }, 500);
                el.data('timer', timer);
            }
        );
    }

    $.fn.hideHeight = function(options) {
        var settings = {
            position:'bottom'
        };
        $.extend(settings, options);

        this.each(function() {
            var element = $(this);

            if(element.data('hideHeight')) return;
            element.data('hideHeight', true);

            var fnLoad = function() {
                var maxHeight;
                var height;

                maxHeight = element.css({height:'', maxHeight:''}).height();
                height = element.css('maxHeight', 'none').height();

                if(element.next('.hideHeight-footer').length) {
                    if(height<=maxHeight) {
                        maxHeight = 0;
                    }

                    if(!element.hasClass('collapse')) {
                        element.css({height:''});
                    } else {
                        element.css({height:maxHeight});
                    }

                    return;
                }

                var header = $('<div class="hideHeight-addon hideHeight-header" />').wrapInner('<div class="content" />');
                var footer = $('<div class="hideHeight-addon hideHeight-footer" />').wrapInner('<div class="content" />');

                if(settings.position=='bottom') {
                    header.hide();
                } else
                if(settings.position=='top') {
                    footer.hide();
                }

                element.before(header);
                element.after(footer);

                if(height<=maxHeight) {
                    maxHeight = 0;
                    element.css({height:''});
                } else {
                    element.css({height:maxHeight});

                    header.addClass('collapse');
                    footer.addClass('collapse');
                    element.addClass('collapse');
                }

                var fnClick = function() {
                    if(!element.hasClass('collapse')) {
                        element.animate({height:maxHeight}, 500).addClass('collapse');
                        header.addClass('collapse');
                        footer.addClass('collapse');
                    } else {
                        element.animate({height:height}, 500, function() {$(this).css('height', '')}).removeClass('collapse');
                        header.removeClass('collapse');
                        footer.removeClass('collapse');
                    }
                }

                header.click(fnClick);
                footer.click(fnClick);
            };

            if($.adaptive) {
                $.adaptive(fnLoad);
            } else {
                fnLoad();
            }
        });
    }

    $.fn.adaptiveImage = function() {
        var self = this;

        $.adaptive(function() {
            self.waitimgs(function() {
                run(self.find('img'));
            })
        });

        function run(imgs) {
            imgs.each(function() {
                var element = $(this);
                var src = element.attr('src');
                if(!src) return;

                var width = element.width();
                var height = element.height();
                var cw=0;
                var ch=0;
                var re = new RegExp('/(\\d+)x(\\d+)/');
                var mat = re.exec(src);
                if(mat) {
                    cw = mat[1];
                    ch = mat[2];
                } else {
                    re = new RegExp('/w(\\d+)/');
                    mat = re.exec(src);
                    if(mat) {
                        cw = mat[1];
                    } else {
                        re = new RegExp('/h(\\d+)/');
                        mat = re.exec(src);
                        if(mat) {
                            ch = mat[1];
                        }
                    }
                }
                if(cw || ch) {
                    var change = false;
                    if((cw && width>cw) || (ch && height>ch)) {
                        change = true;

                        if(cw && ch) {
                            var k = cw/ch;
                            cw = 1000;
                            if(width<500) cw = 500;
                            if(width<300) cw = 300;

                            ch = Math.round(cw/k);
                        } else
                        if(cw) {
                            cw = 1000;
                            if(width<500) cw = 500;
                            if(width<300) cw = 300;
                        } else
                        if(ch) {
                            ch = 1000;
                            if(height<500) ch = 500;
                            if(height<300) ch = 300;
                        }
                    }
                    if(change) {
                        var adaptive;
                        if(cw && ch) {
                            adaptive = cw+'x'+ch;
                        } else
                        if(cw) {
                            adaptive = 'w'+cw;
                        } else
                        if(ch) {
                            adaptive = 'h'+ch;
                        }

                        src = src.replace(re, '/'+adaptive+'/');
                        if(src) {
                            //element.attr('src', src);
                            var img = document.createElement('img');
                            $(img).load(function() {
                                element.attr('src', src);
                                img = null;
                            });
                            img.src = src;
                        }
                    }
                }
            });
        }
    }

    $.fn.iselect = function(option) {
        var settings = {
            addclass:''
        };

        $.extend(settings, option);

        return this.each(function() {
            var element = $(this);

            if(element[0].tagName.toLowerCase() != 'input') {
                return;
            }

            if(element.data('iselect')) return;
            element.data('iselect', true);

            element.change(function() {
                var v = $(this).val();
                if(!parseInt(v)) v = 1;
                $(this).val(v);
            });

            element.wrap('<div class="iselect" />').wrap('<div class="iselect-ipt" />');

            var iselect = element.closest('div.iselect');
            iselect.css({'position':'relative', 'width':element.outerWidth()});
            iselect.data('input', element);
            if(settings['addclass']) {
                iselect.addClass(settings['addclass']);
            }

            if(element.css('margin')) {
                iselect.css('margin', element.css('margin'));
            }

            if(element.css('float')) {
                iselect.css('float', element.css('float'));
            }

            element.removeClass('iselect');

            var btns = $('<div class="iselect-btns" />');
            iselect.append(btns);
            btns.append('<button type="button" class="iselect-up" />').append('<button type="button" class="iselect-down" />')

            iselect.find('.iselect-up').on('click', function(event) {
                event.preventDefault();
                var e = $(this).closest('.iselect').find('input');
                var v = parseInt(e.val());
                e.val(v+1);
                e.trigger('change');
            });

            iselect.find('.iselect-down').on('click', function(event) {
                event.preventDefault();
                var e = $(this).closest('.iselect').find('input');
                var v = parseInt(e.val());
                if(v>1) e.val(v-1);
                e.trigger('change');
            });
        });
    }
})(jQuery);

// jQuery Mask Plugin v1.13.4
// github.com/igorescobar/jQuery-Mask-Plugin
(function(b){"function"===typeof define&&define.amd?define(["jquery"],b):"object"===typeof exports?module.exports=b(require("jquery")):b(jQuery||Zepto)})(function(b){var y=function(a,c,d){a=b(a);var g=this,k=a.val(),l;c="function"===typeof c?c(a.val(),void 0,a,d):c;var e={invalid:[],getCaret:function(){try{var q,b=0,e=a.get(0),f=document.selection,c=e.selectionStart;if(f&&-1===navigator.appVersion.indexOf("MSIE 10"))q=f.createRange(),q.moveStart("character",a.is("input")?-a.val().length:-a.text().length),
    b=q.text.length;else if(c||"0"===c)b=c;return b}catch(d){}},setCaret:function(q){try{if(a.is(":focus")){var b,c=a.get(0);c.setSelectionRange?c.setSelectionRange(q,q):c.createTextRange&&(b=c.createTextRange(),b.collapse(!0),b.moveEnd("character",q),b.moveStart("character",q),b.select())}}catch(f){}},events:function(){a.on("input.mask keyup.mask",e.behaviour).on("paste.mask drop.mask",function(){setTimeout(function(){a.keydown().keyup()},100)}).on("change.mask",function(){a.data("changed",!0)}).on("blur.mask",
    function(){k===a.val()||a.data("changed")||a.triggerHandler("change");a.data("changed",!1)}).on("blur.mask",function(){k=a.val()}).on("focus.mask",function(a){!0===d.selectOnFocus&&b(a.target).select()}).on("focusout.mask",function(){d.clearIfNotMatch&&!l.test(e.val())&&e.val("")})},getRegexMask:function(){for(var a=[],b,e,f,d,h=0;h<c.length;h++)(b=g.translation[c.charAt(h)])?(e=b.pattern.toString().replace(/.{1}$|^.{1}/g,""),f=b.optional,(b=b.recursive)?(a.push(c.charAt(h)),d={digit:c.charAt(h),
    pattern:e}):a.push(f||b?e+"?":e)):a.push(c.charAt(h).replace(/[-\/\\^$*+?.()|[\]{}]/g,"\\$&"));a=a.join("");d&&(a=a.replace(new RegExp("("+d.digit+"(.*"+d.digit+")?)"),"($1)?").replace(new RegExp(d.digit,"g"),d.pattern));return new RegExp(a)},destroyEvents:function(){a.off("input keydown keyup paste drop blur focusout ".split(" ").join(".mask "))},val:function(b){var c=a.is("input")?"val":"text";if(0<arguments.length){if(a[c]()!==b)a[c](b);c=a}else c=a[c]();return c},getMCharsBeforeCount:function(a,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          b){for(var e=0,f=0,d=c.length;f<d&&f<a;f++)g.translation[c.charAt(f)]||(a=b?a+1:a,e++);return e},caretPos:function(a,b,d,f){return g.translation[c.charAt(Math.min(a-1,c.length-1))]?Math.min(a+d-b-f,d):e.caretPos(a+1,b,d,f)},behaviour:function(a){a=a||window.event;e.invalid=[];var c=a.keyCode||a.which;if(-1===b.inArray(c,g.byPassKeys)){var d=e.getCaret(),f=e.val().length,n=d<f,h=e.getMasked(),k=h.length,m=e.getMCharsBeforeCount(k-1)-e.getMCharsBeforeCount(f-1);e.val(h);!n||65===c&&a.ctrlKey||(8!==
    c&&46!==c&&(d=e.caretPos(d,f,k,m)),e.setCaret(d));return e.callbacks(a)}},getMasked:function(a){var b=[],k=e.val(),f=0,n=c.length,h=0,l=k.length,m=1,p="push",u=-1,t,w;d.reverse?(p="unshift",m=-1,t=0,f=n-1,h=l-1,w=function(){return-1<f&&-1<h}):(t=n-1,w=function(){return f<n&&h<l});for(;w();){var x=c.charAt(f),v=k.charAt(h),r=g.translation[x];if(r)v.match(r.pattern)?(b[p](v),r.recursive&&(-1===u?u=f:f===t&&(f=u-m),t===u&&(f-=m)),f+=m):r.optional?(f+=m,h-=m):r.fallback?(b[p](r.fallback),f+=m,h-=m):e.invalid.push({p:h,
    v:v,e:r.pattern}),h+=m;else{if(!a)b[p](x);v===x&&(h+=m);f+=m}}a=c.charAt(t);n!==l+1||g.translation[a]||b.push(a);return b.join("")},callbacks:function(b){var g=e.val(),l=g!==k,f=[g,b,a,d],n=function(a,b,c){"function"===typeof d[a]&&b&&d[a].apply(this,c)};n("onChange",!0===l,f);n("onKeyPress",!0===l,f);n("onComplete",g.length===c.length,f);n("onInvalid",0<e.invalid.length,[g,b,a,e.invalid,d])}};g.mask=c;g.options=d;g.remove=function(){var b=e.getCaret();e.destroyEvents();e.val(g.getCleanVal());e.setCaret(b-
    e.getMCharsBeforeCount(b));return a};g.getCleanVal=function(){return e.getMasked(!0)};g.init=function(c){c=c||!1;d=d||{};g.byPassKeys=b.jMaskGlobals.byPassKeys;g.translation=b.jMaskGlobals.translation;g.translation=b.extend({},g.translation,d.translation);g=b.extend(!0,{},g,d);l=e.getRegexMask();!1===c?(d.placeholder&&a.attr("placeholder",d.placeholder),b("input").length&&!1==="oninput"in b("input")[0]&&"on"===a.attr("autocomplete")&&a.attr("autocomplete","off"),e.destroyEvents(),e.events(),c=e.getCaret(),
    e.val(e.getMasked()),e.setCaret(c+e.getMCharsBeforeCount(c,!0))):(e.events(),e.val(e.getMasked()))};g.init(!a.is("input"))};b.maskWatchers={};var A=function(){var a=b(this),c={},d=a.attr("data-mask");a.attr("data-mask-reverse")&&(c.reverse=!0);a.attr("data-mask-clearifnotmatch")&&(c.clearIfNotMatch=!0);"true"===a.attr("data-mask-selectonfocus")&&(c.selectOnFocus=!0);if(z(a,d,c))return a.data("mask",new y(this,d,c))},z=function(a,c,d){d=d||{};var g=b(a).data("mask"),k=JSON.stringify;a=b(a).val()||
    b(a).text();try{return"function"===typeof c&&(c=c(a)),"object"!==typeof g||k(g.options)!==k(d)||g.mask!==c}catch(l){}};b.fn.mask=function(a,c){c=c||{};var d=this.selector,g=b.jMaskGlobals,k=b.jMaskGlobals.watchInterval,l=function(){if(z(this,a,c))return b(this).data("mask",new y(this,a,c))};b(this).each(l);d&&""!==d&&g.watchInputs&&(clearInterval(b.maskWatchers[d]),b.maskWatchers[d]=setInterval(function(){b(document).find(d).each(l)},k));return this};b.fn.unmask=function(){clearInterval(b.maskWatchers[this.selector]);
    delete b.maskWatchers[this.selector];return this.each(function(){var a=b(this).data("mask");a&&a.remove().removeData("mask")})};b.fn.cleanVal=function(){return this.data("mask").getCleanVal()};b.applyDataMask=function(a){a=a||b.jMaskGlobals.maskElements;(a instanceof b?a:b(a)).filter(b.jMaskGlobals.dataMaskAttr).each(A)};var p={maskElements:"input,td,span,div",dataMaskAttr:"*[data-mask]",dataMask:!0,watchInterval:300,watchInputs:!0,watchDataMask:!1,byPassKeys:[9,16,17,18,36,37,38,39,40,91],translation:{0:{pattern:/\d/},
    9:{pattern:/\d/,optional:!0},"#":{pattern:/\d/,recursive:!0},A:{pattern:/[a-zA-Z0-9]/},S:{pattern:/[a-zA-Z]/}}};b.jMaskGlobals=b.jMaskGlobals||{};p=b.jMaskGlobals=b.extend(!0,{},p,b.jMaskGlobals);p.dataMask&&b.applyDataMask();setInterval(function(){b.jMaskGlobals.watchDataMask&&b.applyDataMask()},p.watchInterval)});
